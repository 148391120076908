@import url('https://fonts.googleapis.com/css2?family=Hahmlet:wght@100;300;400;500&family=Lato:wght@300;400;700&family=Rubik:wght@300;400;600;700&family=Teko:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hahmlet:wght@100;300;400;500&family=Lato:wght@300;400;700&family=Roboto:wght@100;300;400&family=Rubik:wght@300;400;600;700&family=Teko:wght@400;500;600&display=swap');

:root {
  --primary-color: #151a1f;
  --secondary-color: #e30b5c;
  --primary-font: 'Robot', 'sans-serif'; 
  --secondary-font: 'Rubik', 'sans-serif'; 
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  
  html, 
  body {
    font-family: var(--secondary-font);
    background-color: var(--primary-color);
    height: 100vh;
    color: #fff;
  }
  
  a {
    text-decoration: none;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1200px;
    padding: 0 20px;
  }

  .loadMore {
    cursor: pointer;
    width: 8rem;
    margin: 0 auto;
    text-align: center;
    padding: 0.25rem 0.5rem;
    background-color: #000000;
    color: #ffffff;
    font-weight: 600;
    border-radius: 1rem;
    opacity: 0.7;
    margin-top: 2rem;
  }

  /* Nav Buttons */

  .btn-register {
    background-color: #e30b5c;
    padding: 8px;
    border: transparent;
  }

  .btn-register:hover {
    opacity: 0.7;
  }

  .primaryButton {
    cursor: pointer;
    background: #e30b5c;
    border-radius: 1rem;
    border: none;
    padding: 0.85rem 2rem;
    color: #fff;
    font-weight: 600;
    font-size: 1.25rem;
    width: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

/* Navbar Styling */

.navbar {
  position: fixed;
	background-color: #1b252f;
	top: 0;
	right: 100%;
	height: 100vh;
	width: 250px;
	display: flex;
  z-index: 2;
	justify-content: center;
	align-items: center;
	transition: position 2.9s;
	-webkit-transition: position 2.9s;
	-moz-transition: position 2.9s;
	-ms-transition: position 2.9s;
	-o-transition: position 2.9s;
}

.toggle {
	left: 0;
	box-shadow: 1px 0 15px 2px rgba(0, 0, 0, 0.4);
}

.toggle-menu {
	background-color: rgba(0, 0, 0, 0.2);
	position: fixed;
	top: 20px;
	left: 20px;
	width: 40px;
	height: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
  padding: 2px 2px 2px 3px;
	border-radius: 5px;
  border: none;
	cursor: pointer;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.line {
	width: 100%;
	height: 3px;
	border-radius: 5px;
	background-color: #fff;
	transition: transform 0.2s ease-out;
	-webkit-transition: transform 0.2s ease-out;
	-moz-transition: transform 0.2s ease-out;
	-ms-transition: transform 0.2s ease-out;
	-o-transition: transform 0.2s ease-out;
}

.toggle .line1 {
	background-color: #000000;
	transform: scale(0.9) rotateZ(-45deg) translate(-6px, 4px);
}

.toggle .line2 {
	display: none;
}

.toggle .line3 {
	background-color: #000000;
	transform: scale(0.9) rotateZ(45deg) translate(-6px, -4px);
}

.toggle .toggle-menu {
  position: absolute;
	background-color: white;
}

.nav-list {
	list-style: none;
}

.nav-list-item {
	text-align: center;
	padding: 10px 0;
}

.nav-link {
	color: #fff;
	font-size: 22px;
	text-decoration: none;
	position: relative;
	padding-bottom: 4px;
}

.nav-link::before {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background-color: var(--secondary-color);
	transform: scaleX(0);
	transition: transform 0.4s ease-in-out;
	transform-origin: left;
	-webkit-transition: transform 0.4s ease-in-out;
	-moz-transition: transform 0.4s ease-in-out;
	-ms-transition: transform 0.4s ease-in-out;
	-o-transition: transform 0.4s ease-in-out;
}

.nav-link:hover::before {
	transform: scaleX(1);
}

.nav-list-social {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.nav-list-social li {
  list-style: none;
}

.nav-social-item{
  margin-right: 10px;
}

/* End Navbar Styles */

/******** Home Page Main ******/

.setups-home main {
  margin-top: 2rem;
  padding: 5px 0;
}

.setup-img-home {
  border-radius: 2%;
}


.img-wrapper {
  position: relative;
  width: 100%;
}

.img-title {
  position: absolute;
  top: 1;
  bottom: 18%;
  left: 2%;
  right: 0;
  color: #fff;
  font-size: 1rem;
  visibility: hidden;
  opacity: 0;

  transition: opacity .3s, visibility .3s;
}

.img-title-1 {
  position: absolute;
  top: 1;
  bottom: 5%;
  left: 2%;
  right: 0;
  color: #fff;
  font-size: 1rem;
  visibility: hidden;
  opacity: 0;

  transition: opacity .3s, visibility .3s;
}

.img-description {
  position: absolute;
  top: 1;
  bottom: 10%;
  left: 2%;
  right: 0;
  padding: 12px 0 5px 0;
  color: #fff;
  font-size: .9rem;
  visibility: hidden;
  opacity: 0;

  transition: opacity .3s, visibility .3s;
}

.more-like-this {
  position: absolute;
  top: 1;
  bottom: 5%;
  left: 2%;
  right: 0;
  color: #fff;
  font-size: .9rem;
  visibility: hidden;
  opacity: 0;

  transition: opacity .3s, visibility .3s;
}

.img-wrapper:hover .img-description, 
.img-wrapper:hover .img-title, 
.img-wrapper:hover .img-title-1, 
.img-wrapper:hover .more-like-this,
.img-wrapper:hover .trash,
.img-wrapper:hover .edit  {
  visibility: visible;
  opacity: 1;
}

/******** Login Page *******/

.pageContainer {
  width: 700px;
  margin: 0 auto;
  padding: 0 40px;
}

.pageHeader {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 1rem;
}


.input,
.passwordInput,
.emailInput,
.nameInput,
.textarea {
  box-shadow: rgba(0, 0, 0, 0.11);
  border: none;
  background: #ffffff;
  border-radius: 3rem;
  height: 3rem;
  width: 100%;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  padding: 0 3rem;
  font-size: 1rem;
}


.passwordInputDiv {
  position: relative;
}

.passwordInput, .emailInput, .nameInput {
  margin-bottom: 1.2rem;
}

.showPassword {
  cursor: pointer;
  position: absolute;
  top: 18%;
  right: 2%;
  font-size: 1.3rem;
}

.forgotPasswordLink {
  cursor: pointer;
  color: var(--secondary-color);
  font-weight: 600;
  text-align: right;
}


.signInText,
.signUpText {
  font-size: 1.5rem;
  font-weight: 700;
}

.signInButton,
.signUpButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background-color: var(--secondary-color);
  border-radius: 50%;
  border: none;
}

.signInBar,
.signUpBar {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: inherit;
}

.signInButton,
.signUpButton,
.signInText,
.signUpText {
  cursor: pointer;
}

.registerLink {
  margin-top: 4rem;
  color: #f2f2f2;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3rem;
}


/* Google oauth styles */
.socialLogin {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialIconDiv {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  margin: 1.5rem;
  width: 4rem;
  height: 4rem;
  background-color: #ffffff;
  border-radius: 50%;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.socialIconImg {
  width: 100%;
}

/* About Page */
.about {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 700px;
  padding: 0 20px;
}

.aboutHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutCard {
  background-color: #ffffff;
  color: #1b252f;
  border-radius: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  max-width: 500px;
}


/* Account Page */
.account {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 700px;
  padding: 0 20px;
}

.accountHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logOut {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: var(--secondary-color);
  padding: .70rem 0.75rem;
  border-radius: 1rem;
  border: none;
}

.logOutDiv {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.setupFormBtn {
  background-color: #ffffff;
  color: #1b252f;
  border-radius: 1rem;
  padding: 1rem 1.2rem;
  margin-top: 2rem;
  font-weight: 600;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accountCard {
  background-color: #ffffff;
  border-radius: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  max-width: 500px;
}

.accountDetailsHeader {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin-top: 1rem;
}

.personalDetailsText {
  font-weight: 600;
}

.changeAccountDetails {
  cursor: pointer;
  font-weight: 600;
  color: #395572;
}


.accountName,
.accountEmail,
.accountAddress,
.accountAddressActive,
.accountEmailActive,
.accountNameActive {
  all: unset;
  margin: 0.3rem 0;
  font-weight: 600;
  width: 100%;
  color: #151a1f;
}
.accountNameActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.accountEmail,
.accountAddress,
.accountAddressActive,
.accountEmailActive {
  font-weight: 500;
}
.accountEmailActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.accountAddressActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.account-setup {
  margin-top: 10px;
}

/***** Setup Form  *****/

.flex-container {
  display: flex;
  gap: 2rem;
  width: 100%;
}

.formLabel {
  font-weight: 600;
  margin-top: 1rem;
  display: block;
}

.formButtons {
  display: flex;
}

.formButton,
.formInput,
.formInputName,
.formInputSmall,
.formInputFile,
.formButtonActive {
  padding: 0.9rem 3rem;
  background-color: #ffffff;
  font-weight: 600;
  border-radius: 1rem;
  border: none;
  font-size: 1rem;
  margin: 0.5rem 0.5rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.formInput,
.formInputName,
.formInputSmall,
.formInputFile {
  border: none;
  outline: none;
  font-family: 'Montserrat', sans-serif;
}
.formInputSmall,
.formInputFile {
  margin-right: 3rem;
  padding: 0.9rem 0.7rem;
  text-align: center;
}

.formButtonActive {
  background-color: #e30b5c;
  color: #fff;
}

.setupFormButton {
  margin-top: 5rem;
}

.imagesInfo {
  font-size: 0.9rem;
  opacity: 0.75;
}

.formInputFile {
  width: 100%;
  color: #1b252f;
}
.formInputFile::-webkit-file-upload-button {
  background-color: #e30b5c;
  border: none;
  color: #ffffff;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.formFlex {
  display: flex;
}
.formFlex .formLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.formPriceDiv {
  display: flex;
  align-items: center;
}

.trash {
  position: absolute;
  top: 3%;
  bottom: 0;
  left: 1;
  right: 4%;
  color: #fff;
  font-size: .98rem;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;

  transition: opacity .3s, visibility .3s;
}

.edit {
  position: absolute;
  top: 15%;
  bottom: 0;
  left: 1;
  right: 3%;
  color: #fff;
  font-size: 1rem;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;

  transition: opacity .3s, visibility .3s;
}

/* Header Styles */
  header .logo {
    color: #fff;
    text-align: center;
    font-size: 5rem;
    font-weight: 700;
    margin-top: 100px;
    font-family: var(--primary-font);
    height: 100px;
  }
  .logo-color {
    color: var(--secondary-color);
  }


.mb-1 {
  margin-bottom: 1rem;
}



/* Explore Setups */

.exploreHeading,
.exploreCategoryHeading {
  font-weight: 700;
}

.exploreCategoryHeading {
  margin: 2rem 0;
}

.exploreCategories {
  display: flex;
  justify-content: space-between;
}
.exploreCategories a {
  margin: 5px;
  width: 48%;
  color: #fff;
}

.exploreCategoryImg {
  min-height: 115px;
  height: 15vw;
  width: 100%;
  border-radius: 1rem;
  object-fit: cover;
  margin: 0 auto;
}

.exploreCategoryName {
  font-weight: 500;
  text-align: left;
}


.shareIconDiv {
  cursor: pointer;
  position: fixed;
  top: 2%;
  right: 3%;
  z-index: 2;
  background-color:#e30b5c; 
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkCopied {
  position: fixed;
  top: 9%;
  right: 5%;
  z-index: 2;
  background-color: #1b252f;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
}

/* Slider/Setup styles */

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  width: 100%;
}

.alice-carousel__dots {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.alice-carousel__prev-btn,
.alice-carousel__prev-btn-item,
.alice-carousel__next-btn,
.alice-carousel__next-btn-item {
  display: none;
}

.alice-carousel__wrapper {
  margin-bottom: -50px;
}


.sliderimg{
  width: 100%;
  height: 500px;
  object-fit: cover;
}

/* Table */

table {
  padding-top: 20px;
  width: 100%;
}

table th {
  padding: 20px 0;
  font-size: 1.2rem;
  text-align: left;
}

table td {
  padding: 15px;
  text-align: left;
}

table tbody tr {
  background-color:#1b252f;
}

.border-prop td:nth-of-type(2) {
  width: 30px;
}


table tbody tr td:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

table tbody tr td:last-of-type {
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

table tbody tr.border-prop td:first-of-type {
  border-right: 5px solid #e30b5c;
}

/* End Table */

/* Icons */
path {
  stroke: rgb(255, 255, 255);
}


/* Footer */

.footer {
  text-align: center;
  padding: auto;
  height: 200px;
}

.footer a {
  color: var(--secondary-color);
}

.footer h3 {
  padding-top: 100px;
  margin-bottom: 5px;
}

@media(max-width: 768px){
  header .logo {
    font-size: 3.2rem;
  }

  .filter-buttons .filter-btn {
    font-size: 1rem;
    padding: 7px;
    background-color: #fff;
  }

}


@media(max-width: 700px){
  .logOutDiv {
    margin-top: 1rem;
  }

  .pageContainer {
    width: 400px;
  }


  .formButtons, .flex-container {
    display: block;
  }

  .exploreCategories {
    display: block;
  }

  .exploreCategoryName {
    margin: 8px;
  }
}


@media (min-width: 1217px) {
  .explore {
    margin-bottom: 10rem;
  }
}
@media (max-height: 536) {
  .explore {
    margin-bottom: 10rem;
  }
}



@media (min-width: 1024px) {
  .signInBar,
  .signUpBar {
    justify-content: start;
  }

}

@media (min-width: 1024px) {
  .signInButton,
  .signUpButton {
    margin-left: 3rem;
  }
}
